.App {
  text-align: center;
  color: white;
  background: #090f1e;
  min-height: 100vh;
}

.prev {
  color: white;
}

.next {
  color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d94d;
  border-radius: 3px;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-track {
  background: #090f1e;
}

.swiper-pagination-bullet {
  background: #fff9 !important;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
}

.react-tooltip {
  z-index: 1;
}

.PhoneInput {
  background-color: #edf0f7;
  padding: 15px;
  border-radius: 10px;
  width: calc(100% - 30px);
}

.PhoneInput input {
  border: none;
  background-color: #edf0f7;
  outline: none;
  font-size: 12px;
}

::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
}

@media screen and (max-width: 1199px) {
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  .woot-widget-bubble {
    bottom: 80px !important;
  }
}

.menu-active {
  border-bottom-color: #007aff;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.rule-active {
  box-shadow: 0 6px 22px #056dda4d;
  background-image: linear-gradient(to right, #0095ff, #0855c4);
}

.mobile-menu-active {
  background-image: linear-gradient(to bottom right, #0095ff, #0855c4);
  box-shadow: 0 6px 22px #056dda4d;
}

.drawer-container {
  background: linear-gradient(173deg, rgba(37, 46, 76), rgba(25, 32, 51)) !important;
  border-radius: 20px 0 0 20px;
}

.tab-active {
  background-image: linear-gradient(103deg, #108de7 -30%, #0855c4 100%);
  font-weight: 500;
}

.tab-active span {
  color: #fff;
}

.tab-inactive {
  background-color: #fff;
}

.tab-inactive span {
  color: #070c19;
}

.google {
  background-color: rgb(242, 242, 242);
}

.mailru {
  background-color: rgb(0, 95, 249);
}

.yandex {
  background-color: rgb(255, 0, 0);
}

.twitter {
  background-color: rgb(242, 242, 242);
}

.telegram {
  background-color: rgb(55, 174, 226);
}

.whatsapp {
  background-color: #61fd7d;
}

.google-btn {
  background: linear-gradient(267.05deg, #089e4e 0%, #31bc69 99.28%);
  box-shadow: 0 6px 18px #0ea1514d !important;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
  border: none !important;
  opacity: 0.5;
  width: 100%;
  text-align: center !important;
  display: flex !important;
  justify-content: center;
}

.google-btn span {
  color: white;
  font-weight: 600 !important;
  font-size: 15px;
  line-height: 18px;
}

.active {
  opacity: 1;
}

.inactive {
  opacity: 0.5;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  font-size: 1rem;
  animation: spin 1s linear infinite;
}

@media screen and (max-width: 1199px) {
  .woot--bubble-holder {
    display: none !important;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
